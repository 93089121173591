<template>
  <div id="container" v-if="fetch_data">

    <section id="event">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>申し込み<br class="sp">フォーム</p>
          </div>
          <div class="step_box flex">
            <div class="num">2</div>
            <p>申し込み内容の<br class="sp">確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">3</div>
            <p>決済情報の<br class="sp">入力</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>参加申し込みを<br class="sp">受け付けました</p>
          </div>
        </div>

        <div class="cts_box">
          <h2 class="sub_ttl"><span>月例会参加お申込み</span></h2>
          <h3 class="min_ttl">月例会概要</h3>
          <div class="df_table">
            <dl class="flex">
              <dt>名称</dt>
              <dd>{{ model.title }}</dd>
            </dl>

            <dl class="flex">
              <dt>開催日</dt>
              <dd>{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }} {{ model.open_time }}</dd>
            </dl>
            <dl class="flex" v-if="model.place_text">
              <dt>会場</dt>
              <dd v-html="model.place_text"></dd>
            </dl>
            <dl class="flex" v-if="model.rank_price !== null">
              <dt>価格</dt>
              <dd v-if="model.required_social">
                {{ delimited(model.rank_price + model.social_price) }}円･税込
              </dd>
                <dd v-else>
                {{ delimited(model.rank_price) }}円･税込
              </dd>
            </dl>
          </div>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">月例会メニュー</h3>
          <div class="reg_set first">
            <div class="event_entry_list">
              <div class="tax_ttl flex">
                <h4>参加を希望する月例会のメニューを選択してください。</h4>
                <p class="tax_caution bold red">※表示はすべて税込価格です</p>
              </div>

              <template v-if="model.required_social && model.social_price">
                <div class="event_entry_box flex check_radio" v-if="model.rank_price !== null">
                  <input type="radio" id="event01" v-model="user.join_social_party" @change="priceChange"
                         v-bind:value="true">
                  <label class="flex" for="event01">
                    <div class="ttl">
                      <template v-if="model.held_type === 'held_type_normal'">月例会</template>
                      参加費 ＋ 懇親会
                    </div>
                    <div class="class">{{ current_user.rank_label }}</div>
                    <div class="price">¥{{ delimited(model.rank_price + model.social_price) }}</div>
                    <div class="btn"><span></span>
                      <p>選択する</p></div>
                  </label>
                </div>

                <div class="event_entry_box flex check_radio" v-if="model.rank_price === null">
                  <input type="radio" id="event02" v-model="user.join_social_party" @change="priceChange"
                         v-bind:value="true">
                  <label class="flex" for="event02">
                    <div class="ttl">
                      <template v-if="model.held_type === 'held_type_normal'">月例会</template>
                      懇親会
                    </div>
                    <div class="class">{{ current_user.rank_label }}</div>
                    <div class="price">¥{{ delimited(model.social_price) }}</div>
                    <div class="btn"><span></span>
                      <p>選択する</p></div>
                  </label>
                </div>
              </template>

              <template v-else-if="model.rank_price !== null && model.social_price">
                <div class="event_entry_box flex check_radio">
                  <input type="radio" id="event03" v-model="user.join_social_party" @change="priceChange"
                         v-bind:value="true">
                  <label class="flex" for="event03">
                    <div class="ttl">
                      <template v-if="model.held_type === 'held_type_normal'">月例会</template>
                      参加費 ＋ 懇親会
                    </div>
                    <div class="class">{{ current_user.rank_label }}</div>
                    <div class="price">¥{{ delimited(model.rank_price + model.social_price) }}</div>
                    <div class="btn"><span></span>
                      <p>選択する</p></div>
                  </label>
                </div>

                <div class="event_entry_box flex check_radio">
                  <input type="radio" id="event04" v-model="user.join_social_party" @change="priceChange"
                         v-bind:value="false">
                  <label class="flex" for="event04">
                    <div class="ttl">
                      <template v-if="model.held_type === 'held_type_normal'">月例会</template>
                      参加費
                    </div>
                    <div class="class">{{ current_user.rank_label }}</div>
                    <div class="price">¥{{ delimited(model.rank_price) }}</div>
                    <div class="btn"><span></span>
                      <p>選択する</p></div>
                  </label>
                </div>
              </template>

              <template v-else-if="model.rank_price !== null && !model.social_price">
                <div class="event_entry_box flex check_radio">
                  <input type="radio" id="event05" v-model="user.join_social_party" @change="priceChange"
                         v-bind:value="false">
                  <label class="flex" for="event05">
                    <div class="ttl">
                      <template v-if="model.held_type === 'held_type_normal'">月例会</template>
                      参加費
                    </div>
                    <div class="class">{{ current_user.rank_label }}</div>
                    <div class="price">¥{{ delimited(model.rank_price) }}</div>
                    <div class="btn"><span></span>
                      <p>選択する</p></div>
                  </label>
                </div>
              </template>

              <template v-else>
                <div class="event_entry_box flex check_radio">
                  <input type="radio" id="event06" v-model="user.join_social_party" @change="priceChange"
                         v-bind:value="true">
                  <label class="flex" for="event06">
                    <div class="ttl">
                      <template v-if="model.held_type === 'held_type_normal'">月例会</template>
                      懇親会
                    </div>
                    <div class="class">{{ current_user.rank_label }}</div>
                    <div class="price">¥{{ delimited(model.social_price) }}</div>
                    <div class="btn"><span></span>
                      <p>選択する</p></div>
                  </label>
                </div>
              </template>
            </div>
          </div>

          <!--
          <select v-if="model.required_social && model.social_price" v-model="user.join_social_party"
                  @change="priceChange">
            <option v-bind:value="true" v-if="model.rank_price !== null" selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              1参加費 ＋ 懇親会：¥{{ delimited(model.rank_price + model.social_price) }}
            </option>
            <option v-bind:value="true" v-else selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              2懇親会：¥{{ delimited(model.social_price) }}
            </option>
          </select>

          <select v-else-if="model.rank_price !== null && model.social_price" v-model="user.join_social_party"
                  @change="priceChange">
            <option v-bind:value="true" selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              3参加費 ＋ 懇親会：¥{{ delimited(model.rank_price + model.social_price) }}
            </option>
            <option v-bind:value="false">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              4参加費：¥{{ delimited(model.rank_price) }}
            </option>
          </select>

          <select v-else-if="model.rank_price !== null && !model.social_price" v-model="user.join_social_party"
                  @change="priceChange">
            <option v-bind:value="false" selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              5参加費：¥{{ delimited(model.rank_price) }}
            </option>
          </select>

          <select v-else v-model="user.join_social_party">
            <option v-bind:value="true" selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              6懇親会：¥{{ delimited(model.social_price) }}
            </option>
          </select>
        -->

        </div>

        <div class="cts_box" v-if="fetch_data.coupon">
          <h3 class="min_ttl">利用可能なクーポンがあります</h3>
          <p class="lead_txt">
            現在以下のクーポンが利用できます。クーポンが適用中になっていることをご確認ください。
          </p>
          <div class="scrl_box coupon workshop">
            <div class="inner">
              <ul class="coupon_list">
                <li class="flex">
                  <div class="thumb"><img v-bind:src="fetch_data.coupon.image_file_url" alt=""></div>
                  <div class="detail">
                    <div class="head flex">
                      <h3>{{ fetch_data.coupon.title }}</h3>
                      <div class="dead_line">有効期限：<span>{{ moment(fetch_data.coupon.close_date, 'YYYY年MM月DD日') }}</span>
                      </div>
                    </div>
                    <p>
                      {{ fetch_data.coupon.description }}
                    </p>
                  </div>
                  <div class="btn_wrap">
                    <a href="javascript:void(0);" v-if="!user.use_coupon" @click="useCouponClicked(true)"
                       class="btn" v-bind:class="{disabled: !useCouponPrice()}">クーポンを利用する</a>
                    <a href="javascript:void(0);" v-else @click="useCouponClicked(false)"
                       class="btn active">クーポン適用中</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="cts_box claim workshop" v-bind:class="{ 'point-disabled': !pointUsable() }">
          <h3 class="min_ttl">ポイントの利用</h3>
          <p class="lead_txt">ポイントを利用する場合は、ポイント数を入力の上「ポイントを利用する」ボタンを押してください。</p>
          <table class=""><!-- ポイント利用できない場合はclass="point_none" を追加 -->
            <tbody>
            <tr class="head">
            </tr>
            <tr>
              <td class="date">現在のポイント数</td>
              <td class="price">{{ delimited(model.total_points) }}<span>ポイント</span></td>
              <td class="point flex">
                <h3 class="sp">ポイント利用額（1ポイント＝1円）</h3>
                <p>ご利用ポイントを入力</p>
                <div class="point_input">
                  <input type="text" v-model="user.use_point"
                         @input="user.use_point = replaceFullToNumber(user.use_point)"
                         v-bind:disabled="model.total_points === 0"
                         maxlength="6" class="point">
                  <p class="caution">最大 {{ delimited(Math.min(model.total_points, user.usable_points)) }}
                    ポイントまでご利用いただけます</p>
                  <p>1ポイント＝1円として利用できます</p>
                </div>
                <p class="bold">ポイント</p>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="btn">
                <button type="button" class="submit light skeleton"
                        v-bind:disabled="model.total_points === 0"
                        @click="usePointClicked">ポイントを利用する
                </button>
              </td>
              <!--<td colspan="3" class="btn"><input type="submit" class="submit light skeleton" value="現在ポイントの利用はできません" disabled></td> ポイント利用できない場合-->
            </tr>
            </tbody>
          </table>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">お会計内容</h3>
          <p class="lead_txt">参加を希望されるすべての月例会の合計額を表示しています。</p>
          <div class="register_box">
            <template v-if="model.rank_price !== null">
              <dl class="flex">
                <dt>{{ model.title }}</dt>
                <dd class="item">{{ current_user.rank_label }}</dd>
                <dd class="price">¥{{ delimited(model.rank_price) }}</dd>
              </dl>
              <dl class="flex" v-if="user.join_social_party">
                <dt></dt>
                <dd class="item">懇親会参加費</dd>
                <dd class="price">¥{{ delimited(model.social_price) }}</dd>
              </dl>
            </template>

            <template v-else>
              <dl class="flex">
                <dt>{{ model.title }}</dt>
                <dd class="item">懇親会参加費</dd>
                <dd class="price">¥{{ delimited(model.social_price) }}</dd>
              </dl>
            </template>

            <dl class="flex" v-if="user.use_coupon">
              <dt>クーポンのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">¥-{{ delimited(discountCouponPrice()) }}<span v-if="false">受講額 ¥-{{ delimited(fetch_data.coupon.price) }}%オフ</span></dd>
            </dl>
            <dl class="flex" v-if="user.point">
              <dt>ポイントのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">-{{ delimited(user.point) }}</dd>
            </dl>
            <dl class="flex total">
              <dt></dt>
              <dd class="item">お会計合計：</dd>
              <dd class="price">¥{{ delimited(user.total_price) }}</dd>
            </dl>
          </div>
        </div>

        <div class="cts_box" id="entry_form">
          <h3 class="min_ttl">申し込みフォーム</h3>
          <form @submit="update" id="form">
            <div class="df_table">
              <dl class="flex">
                <dt>おなまえ</dt>
                <dd>{{ current_user.last_name }} {{ current_user.first_name }}</dd>
              </dl>
              <dl class="flex">
                <dt>塾生番号</dt>
                <dd>{{ current_user.number }}</dd>
              </dl>
            </div>

            <div class="button_wrap mt50">
              <p class="alnC mb20" v-if="false">
                すべての項目に入力したら<br class="sp">こちらのボタンを押してください。
              </p>
              <input type="submit" class="submit light arrow" value="申し込み内容の確認">
            </div>

          </form>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    let _init = false
    if (!this.$store.state.user) {
      this.$store.state.user = this.$route.meta.current_user
      this.$store.state.user.email_confirmation = this.$store.state.user.email
      this.$store.state.user.memo = null
      if (this.$store.state.user.enable_primary_address) {
        this.$store.state.user.zip = this.$store.state.user.opt_zip
        this.$store.state.user.prefecture = this.$store.state.user.opt_prefecture
        this.$store.state.user.address1 = this.$store.state.user.opt_address1
        this.$store.state.user.address2 = this.$store.state.user.opt_address2
        this.$store.state.user.address3 = this.$store.state.user.opt_address3
      }
      this.$store.state.user.use_coupon = false
      this.$store.state.user.usable_points = 0
      this.$store.state.user.use_point = null
      this.$store.state.user.point = 0
      this.$store.state.user.price = 0
      this.$store.state.user.use_coupon_price = 0
      this.$store.state.user.total_price = 0
      _init = true
    } else {
      _init = false
    }
    this.$store.state.user.monthly_live_id = this.$route.params.id
    this.$store.state.user.user_id = this.$store.state.user.id
    this.$store.state.user.join_social_party = true
    this.$store.state.user.post = null

    return {
      id: this.$route.params.id,
      fetch_data: null,
      model: null,
      errors: false,
      validation_errors: {},
      user: this.$store.state.user,
      init: _init
    }
  },
  mounted() {
    // form token
    this.setFormToken('Getsurei')

    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}getsurei/page/${this.id}.json`, {
            params: {
              draft: this.$route.query.draft,
            }
          })
          .then(response => {
            this.fetch_data = response.data
            this.model = response.data.monthly_live
            if (!this.model.join_rank) {
              // 参加できない
              this.$router.push({name: 'UsersCustomerRank'})
            }
            // 懇親会の存在確認
            if (this.user.join_social_party && !this.model.social_price) {
              this.user.join_social_party = false
            }
            if (this.fetch_data.coupon != null) {
              this.useCouponClicked(true)
            }
            this.priceChange(this.init)

            if (!this.model.request_enabled && !this.model.lottery_enabled) {
              setTimeout(() => {
                alert('現在、こちらにはお申込みが出来ません。\n前のページに戻ります。')
                this.$router.go(-1)
              }, 500)
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    useCouponClicked(active) {
      if (active && !this.useCouponPrice()) active = false
      this.user.use_coupon = active
      if (this.user.use_coupon) {
        this.user.coupon_id = this.fetch_data.coupon.id
        this.user.coupon_price = this.fetch_data.coupon.price
      } else {
        this.user.coupon_id = this.user.coupon_price = null
      }
      this.priceChange()
    },

    priceChange(init = true) {
      if (init) this.user.point = this.user.use_point = null

      // 使えるポイント値を計算
      this.user.usable_points = 0
      if (this.model.point_usable && this.model.rank_price !== null && this.model.rank_price > 0) {
        this.user.usable_points += this.model.rank_price
      }
      if (this.user.join_social_party && this.model.point_usable_social && this.model.social_price !== null && this.model.social_price > 0) {
        this.user.usable_points += this.model.social_price
      }
      this.user.price = this.useCouponPrice()
      this.user.usable_points = Math.min(this.user.usable_points, this.model.total_points)
      this.user.usable_points = Math.min(this.user.usable_points, this.user.price)

      // 合計金額
      this.user.total_price = this.user.price - this.user.point
    },

    usePointClicked() {
      if (isNaN(this.user.use_point)) {
        this.user.use_point = null
        this.user.point = 0
        this.priceChange()
        return
      }
      this.user.use_point = Number(this.user.use_point)
      if (this.user.use_point === 0) {
        this.user.use_point = null
        this.user.point = 0
        this.priceChange()
        return
      }

      if (this.user.use_point > this.user.usable_points) {
        this.user.use_point = this.user.usable_points
      }
      this.user.point = this.user.use_point

      this.user.price = this.useCouponPrice()
      // 合計金額
      this.user.total_price = this.user.price - this.user.point
    },

    discountCouponPrice() {
      if (!this.user.use_coupon) {
        return 0
      } else {
        let total_price = this.model.rank_price
        if (this.model.required_social || this.user.join_social_party) {
          total_price += this.model.social_price
        }
        return Math.min(total_price, this.fetch_data.coupon.price)
      }
    },

    useCouponPrice() {
      let total_price = this.model.rank_price
      if (this.model.required_social || this.user.join_social_party) {
        total_price += this.model.social_price
      }
      if (!total_price) {
        this.user.use_coupon = false
      }

      if (!this.user.use_coupon) {
        return total_price
      } else {
        return Math.max(total_price - this.fetch_data.coupon.price, 0)
      }
    },

    pointUsable() {
      return this.user.usable_points > 0
    },

    update(event) {
      event.preventDefault()
      this.axios
          .post(`${this.env.api_base_url}getsurei/validate.json`, {
            monthly_live_user: this.user,
          })
          .then(() => {
            this.$router.push({name: 'GetsureiConfirm', params: {id: this.model.id}, query: this.$route.query})
          })
          .catch((error) => {
            // this.errorAjax(error)
            this.scrollTo(`#form`)
            if (error.response.data.errors) {
              this.validation_errors = error.response.data.validation_errors
              this.errors = true
            }
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>

<style>
.point-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
